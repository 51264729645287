.first-sec {
  /* background-image: url("./back.webp"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  margin-top: 60px;
  background-attachment: fixed;
}
