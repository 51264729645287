* {
  font-family: "Teko", sans-serif;
  scroll-behavior: smooth;
  font-size: 50px;
}

::-webkit-scrollbar {
  background-color: black;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #f0bc1d);
  border-radius: 10px;
}
.page-title {
  font-weight: 700;
  /* font-family: "Poppins", sans-serif; */
  letter-spacing: 3px;
  /* font-size: 38px; */
  font-size: 40px;
  text-transform: uppercase;
  color: black;
}
