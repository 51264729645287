.franchise__modal--content {
  max-width: 1024px;
  width: 100%;
  padding: 0 4%;
  padding-top: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.franchise__modal--content {
  padding-top: 30px;
  flex-direction: column;
}

.franchise__modal--card {
  width: 400px !important;

  // max-width: 300px;
  // min-width: 200px;
  height: 300px;
  background-color: #292929;
  margin: 10px;
  margin-bottom: 200px;
  border-radius: 30px 0 30px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.franchise__modal--icon {
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 80px;
  background: linear-gradient(
    90deg,
    #f0bb1da9 0%,
    #f0bc1d 40%,
    rgba(0, 0, 0, 0.28) 60%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0.8s ease;
  background-position: 0px;
  background-size: 200px;
}

.franchise__modal--card .franchise__modal--title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.franchise__modal--card .franchise__modal--text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.franchise__modal--card:hover {
  height: 270px;
}

.franchise__modal--card:hover .franchise__modal--info {
  height: 90%;
}

.franchise__modal--card:hover .franchise__modal--text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

.franchise__modal--card:hover .franchise__modal--icon {
  background-position: -120px;
  transition: all 0.3s ease;
}

.franchise__modal--card:hover .franchise__modal--icon img {
  // background-color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
  transition: all 0.3s ease;
}
.franchise__modal--icon img {
  height: 45px;
  width: 45px;
}
.franchise__modal--card:hover .franchise__modal--icon {
  background-color: #fff;
}
@media (max-width: 991px) {
  .franchise__modal--card {
    width: auto !important;
    padding: 45px;
  }
}
