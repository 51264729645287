.footer-content {
  position: relative;
  /* top: 87px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../assets/illu1.png");
  background-size: contain;
}
.footer-text {
  color: white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
  border-top: 1px solid #000;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.social li {
  list-style: none;
  margin: 5px;
}

.social li a {
  display: inline-block;
  background-color: #222;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.2% ease-in-out;
}

.social li a:hover {
  background-color: #f0bc1d;
  transform: translateY(-10px);
}

.social li a img {
  filter: invert(1);
  transform: scale(0.5);
}

/* @media (max-width: 991px) {
  .social {
    bottom: 30px;
    left: 40px;
  }
} */
