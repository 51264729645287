.header-101 {
  height: 100vh;
  width: auto;
  // top: 50px;
  background-image: url("./back.webp");
  // margin-top: 10px;
  background-position: center 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .box-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-center {
      display: flex;
      padding: 50px;
      justify-content: space-evenly;
      align-items: center;

      h1 {
        font-size: 70px;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: bolder;
        color: #f0bc1d;
        animation: move-in-up ease-in-out 0.7s;
      }
    }

    @keyframes move-in-up {
      0% {
        opacity: 0;
        transform: translateY(-100px);
      }
      80% {
        transform: translateY(1px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .order-online {
      letter-spacing: 3px;
      text-transform: uppercase;
      display: block;
      margin-top: 20px;
      background-color: black;
      text-decoration: none;
      padding: 10px;
      color: white;
      cursor: pointer;
      border: 2px solid black;

      transition: all 0.5s;
    }
    .order-online:hover {
      background-color: transparent;
      border: 2px solid black;
      transition: all 0.5s;
    }
  }
}
@media (max-width: 1024px) {
  .header-101 {
    // background-image: url("./tab1.png");
  }
  .header-101 .box-2 .box-2-center h1 {
    font-size: 70px;
  }
}
@media (max-width: 750px) {
  .header-101 {
    top: 44px;
    // background-image: url("./mob1.png");
    // background-attachment: scroll;
  }
  .header-101 .box-2 .box-2-center h1 {
    font-size: 30px;
  }
}
// @media (min-width: 768px) {
//   .header-101 {
//     background-image: url("./tab.png");
//   }
//   .header-101 .box-2 .box-2-center h1 {
//     font-size: 70px;
//   }
// }

@media (max-width: 530px) {
  .header-101 {
    top: 50px;
    background-image: url("./mob2.webp");
    background-attachment:scroll;
    margin-top: -50px;
    border: 2px solid green;
  }
}
