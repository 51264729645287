// VARIABLES // ============================== //
$bg-color: #424242;
$hl-color: #fabc21;
$muted-color: mix(rgb(0, 0, 0), $bg-color, 70%);
$trans-time: 300ms;
$width: 100%;

// FORM // ============================== //
form {
  width: 100%;
  // margin: 45px auto;

  h2 {
    text-align: center;
    color: #fabc21;
  }
  h5 {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  hr.sep {
    background: #fabc21;
    box-shadow: none;
    border: none;
    height: 2px;
    width: 25%;
    margin: 0px auto 45px auto;
  }
  .emoji {
    font-size: 1.2em;
  }
}

.group {
  position: relative;
  margin: 45px 0;
}

.franchise-subtitle {
  font-size: 25px;
  text-align: center;
  color: white;
}
// INPUTS // ============================== //
textarea {
  resize: none;
}

input,
select,
textarea {
  background: none;
  color: #f0bc1d;
  font-size: 22px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: $width;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:valid ~ label {
    top: -14px;
    font-size: 22px;
    color: $hl-color;
  }
  &:focus ~ .bar:before {
    width: $width;
  }
}

input[type="password"] {
  letter-spacing: 0.3em;
}

label {
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: $trans-time ease all;
}

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $hl-color;
    transition: $trans-time ease all;
    left: 0%;
  }
}

// BUTTONS // ============================== //
.btn {
  background: #fff;
  color: mix(black, $muted-color, 25%);
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 22px;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    color: mix(black, $muted-color, 30%);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
  }
  &.btn-link {
    background: $hl-color;
    color: mix(white, $hl-color, 80%);
    &:hover {
      background: darken($hl-color, 5%);
      color: mix(white, $hl-color, 85%);
    }
  }
  &.btn-submit {
    background: $hl-color;
    color: mix(white, $hl-color, 70%);
    &:hover {
      background: darken($hl-color, 5%);
      color: mix(white, $hl-color, 85%);
    }
  }
  &.btn-cancel {
    background: #eee;
    &:hover {
      background: darken(#eee, 5%);
      color: mix(black, $muted-color, 30%);
    }
  }
}

.btn-box {
  text-align: center;
  margin: 50px 0;
}
