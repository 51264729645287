.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  background-color: white;
  padding: 3%;
}
.close-btn {
  background-color: black;
}
.modal-header,
.modal-footer {
  border: 0;
  margin: 2%;
  padding: 10px;
}
.modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 10px auto;
  height: auto;
}

.modal-body p {
  color: #000;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-footer .apply {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0bc1d;
  text-decoration: none;
  width: 100px;
  height: 40px;
  color: #000;
  border-radius: 5px;
  transition: 0.3s;
}

.modal-footer .apply:hover {
  background-color: transparent;
  border: 1px solid #f0bc1d;
  transition: 0.3s;
}

.modal-footer .close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  text-decoration: none;
  width: 100px;
  height: 40px;
  color: whitesmoke;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}

.modal-footer .close-btn:hover {
  background-color: transparent;
  border: 1px solid black;
  transition: 0.3s;
  color: black;
  cursor: pointer;
}

@media (max-width: 715px) {
  .modal {
    width: 100%;
  }
  .modal-body {
    height: 70vh;
    overflow-y: auto;
  }
  .modal-content {
    width: auto;
    height: auto;
  }
}
