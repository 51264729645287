@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
/* written by riliwan balogun https://www.facebook.com/riliwan.rabo*/
.app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  position: relative;
  width: 240px;
  height: 240px;
  background: linear-gradient(black, #f0bc1d);
  border-radius: 50%;
  margin: 40px;
}

.circle:hover {
  animation: animate 0.5s linear;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle::after {
  content: "1.Powerful brand association ";
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 35px;
  right: 35px;
  bottom: 35px;
  left: 35px;
  border-radius: 50%;
  background-color: aliceblue;
  padding: 10px;
  font-size: 22px;
}

.circle span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(black, #f0bc1d);
  border-radius: 50%;
}

.circle span:nth-child(1) {
  filter: blur(5px);
}

.circle span:nth-child(2) {
  filter: blur(10px);
}
.circle span:nth-child(3) {
  filter: blur(20px);
}
.circle span:nth-child(4) {
  filter: blur(55px);
}

.c1::after {
  content: "Powerful brand Association";
}
.c2::after {
  content: "A to Z Product Supply";
}
.c3::after {
  content: "Manpower Guarantee";
}
.c4::after {
  content: "Advertising and Marketing Programs";
}
.c5::after {
  content: "Not dependent on Skilled labour";
}
.c6::after {
  content: "Centralized Supply - Uniform Taste and Quality";
}
.c7::after {
  content: "New products & Development";
}
.c8::after {
  content: "Site Section and Development Assistance";
}
.c9::after {
  content: "Operational and Management Support";
}
.c10::after {
  content: "Low Investment, High Margin";
}
.c11::after {
  content: "Accounts and Control System";
}

@media (max-width: 425px) {
  .circle {
    width: 200px;
    height: 200px;
    margin: 14px;
  }
}

#home {
  background-image: url("../assets/illu1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
#profile {
  background-image: url("../assets/illu1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
#settings {
  background-image: url("../assets/illu1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 112px;
  /* margin-top: 112px; */
}
#model {
  background-image: url("../assets/illu1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
#messages {
  background-image: url("../assets/illu1.png");
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
.lis-row {
  display: flex;
  justify-content: center;
  margin-bottom: 76px;
}
.lis-col {
  margin: 10px 35px;
}
.list-type2 ol {
  counter-reset: li;
  list-style: none;
  *list-style: decimal;
}
@media (max-width: 991px) {
  .lis-row {
    flex-direction: column;
  }
}
.list-type2 a {
  position: relative;
  display: flex;
  padding: 2em 2em 2em 2em;
  margin: 0.5em 0;
  width: auto;
  border-radius: 20px 0 20px 0;
  background: #f0bb1dce;
  color: #444;
  font-size: 19px;
  /* border:.3em solid #000; */
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.list-type2 a:hover {
  background: #d6d4d4;
  text-decoration: none;
  transform: scale(1.1);
}

.list-type2 a:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -1.3em;
  top: 50%;
  margin-top: -1.3em;
  background: linear-gradient(to right, #824baf, #ccc);
  height: 3em;
  width: 3em;
  line-height: 2em;
  /* border: .3em solid #000; */
  border-radius: 20px 0 20px 0;
  text-align: center;
  font-weight: bold;
  color: #000;
}

.board {
  width: 100%;

  height: auto;

  background: url("../assets/illu1.png");
  /* no-repeat; */
  /* background-color: #000; */

  /* background-size: cover; */

  /*box-shadow: 10px 10px #ccc,-10px 20px #ddd;*/
}
.page--title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 69px;
}
.fran_head {
  color: #000;
}
.white-icons {
  filter: invert(1);
}
.fran_para,
.points {
  color: white;
  font-size: 22px;
}
.tab-title {
  font-size: 21px;
  margin: -10px;
  color: #f0bc1d;
}
.pag {
  margin-top: 8 rem;
}
.tab-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fran_req {
  line-height: 1.6;
}
.franchise-u {
  overflow: hidden;
  margin-bottom: -145px;
}
.board h2 {
  text-align: center;
}
:root {
  --bg: #fdfdfd;
  --highlight1: #f0bc1d;
  --highlight2: #ee8fc9;
  --color: #1a1e24;
  --font-number: Montserrat, Roboto, Helvetica, Arial, sans-serif;
  --font-head: "Space Mono", Consolas, Menlo, Monaco, "Courier New", monospace;
  --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

ul.list {
  list-style-type: circle;
}

.item {
  display: flex;
  align-items: center;
  clear: both;
  counter-increment: list;
  padding-bottom: 3rem;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.375;
  position: relative;
  margin-left: 2%;
}

.madhubas {
  margin-bottom: 25px;
  font-size: 22px;
}

.item-points {
  size: 30;
  border-radius: 50% 50% 50% 50%;
  background-color: var(--highlight2);
}
.list-icon {
  height: auto;
  width: auto;
  color: #f0bc1d;
  margin-right: 10px;
}
.item:after {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  top: 0;
  left: 0;
  content: "";
  background: var(--highlight1);
  z-index: -1;
  border-top-left-radius: 3px;
}

.headline {
  padding: 0rem 0 0 0;
  margin: 0 0 1rem 0;
  font: normal 2rem var(--font-head);
}

/* Demo styles */
.board .nav-tabs {
  position: relative;
  /* border-bottom: 0; */
  /* width: 80%; */
  margin: 40px auto;
  margin-bottom: 0;
  box-sizing: border-box;
}
.points {
  padding: 25px;
  background-color: black;
}
.impor {
  color: #f0bc1d;
}
.board > div.board-inner {
  background: rgb(0, 0, 0);
  background-size: 30%;
}

p.narrow {
  margin: 10px auto;
  line-height: 1.9;
}

.liner {
  height: 2px;
  background: #f0bc1d;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 55%;
}
.tab-click {
  background: transparent;
  border: none;
  cursor: pointer;
}
.tab-click:hover {
  border: none;
}
.tab-title {
  color: #f0bc1d;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
  background-color: transparent;
}

span.round-tabs {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: white;
  position: absolute;
  left: 0;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: -20px;
}

span.round-tabs.one {
  color: rgb(7, 7, 7);
  border: 2px solid rgb(34, 194, 34);
  top: 0px;
  margin: 20px auto;
}

li.active span.round-tabs.one {
  background: #febe29 !important;
}

span.round-tabs.two {
  color: #000;
  border: 2px solid #febe29;
  top: 0px;
  margin: 20px auto;
}

li.active span.round-tabs.two {
  background: #febe29 !important;
  border: 2px solid #ddd;
  color: #febe29;
}

span.round-tabs.three {
  color: #3e5e9a;
  border: 2px solid #3e5e9a;
  top: 0px;
  margin: 20px auto;
}

li.active span.round-tabs.three {
  background: #febe29 !important;
  border: 2px solid #ddd;
  color: #3e5e9a;
}

span.round-tabs.four {
  color: #f1685e;
  border: 2px solid #f1685e;
  top: 0px;
  margin: 20px auto;
}

li.active span.round-tabs.four {
  background: #febe29 !important;
  border: 2px solid #ddd;
  color: #f1685e;
}

span.round-tabs.five {
  color: #999;
  border: 2px solid #999;
  top: 0px;
  margin: 20px auto;
}

li.active span.round-tabs.five {
  background: #febe29 !important;
  border: 2px solid #ddd;
  color: #999;
}

.nav-tabs > li.active > a span.round-tabs {
  background: #fafafa;
}
.nav-tabs > li {
  width: 20%;
}

.nav-tabs > li:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #ddd;
  transition: 0.1s ease-in-out;
}
.nav-tabs > li.active:after {
  content: " ";
  position: absolute;
  left: 47%;
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: #ddd;
}
.nav-tabs > li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.nav-tabs > li a:hover {
  background: transparent;
}

.tab-pane {
  position: relative;
  padding-top: 50px;
}
.tab-content .head {
  letter-spacing: 1px;
  font-size: 40px;
  text-transform: uppercase;
  padding-bottom: 10px;
  color: #f0bc1d;
  background: rgba(0, 0, 0, 0.5);
  /* width: fit-content; */
  /* display: flex; */
  width: 100%;
  padding: 20px;
}
.btn-outline-rounded {
  padding: 10px 40px;
  margin: 20px 0;
  border: 2px solid transparent;
  border-radius: 25px;
}
.icon {
  height: 35px;
  width: 35px;
  top: 50%;
}

.btn.green {
  background-color: #5cb85c;
  /*border: 2px solid #5cb85c;*/
  color: #ffffff;
}

.nav-tabs > li {
  padding: 20px;
}

li::after {
  position: relative !important;
}
@media (max-width: 1199px) {
  .madhubas {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .board {
    width: 100%;
    height: auto !important;
  }
  .madhubas {
    width: 100%;
  }
  .board > div.board-inner {
    padding-top: -5%;
    padding-bottom: 10%;
    /* padding-top: -5%; */
  }
  span.round-tabs {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    z-index: 0;
  }
  .tab-content .head {
    font-size: 20px;
  }
  .points {
    margin: 0;
    padding-bottom: 70px;
  }
  .nav-tabs > li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .nav-tabs > li.active:after {
    content: " ";
    position: absolute;
    left: 36%;
  }

  .btn-outline-rounded {
    padding: 12px 20px;
  }
  .icon {
    height: 25px;
    width: 25px;
    top: 50%;
  }

  span.round-tabs.one {
    margin: 40px auto;
  }
  span.round-tabs.two {
    margin: 40px auto;
  }
  span.round-tabs.three {
    margin: 40px auto;
  }
  span.round-tabs.four {
    margin: 40px auto;
  }
  span.round-tabs.five {
    margin: 40px auto;
  }
  .liner {
    display: none;
  }
  .tab-title {
    font-size: 13px;
  }
  li::after {
    display: none;
  }
  .nav-tabs > li {
    padding: 0px;
  }
}

.containers {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  z-index: 1;
  padding-bottom: 2%;
}

.containers .card {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 30px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: #f0bc1d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.containers .card .contents {
  padding: 20px;
  text-align: center;
  transform: translateY(100px);
  opacity: 1;
  transition: 0.5s;
}

.containers .card .contents .first .imgBx {
  position: relative;
  width: 250px;
  height: 250px;
  /* border-radius: 50%; */
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}

.containers .card .contents .first .imgBx img {
  /* border-radius: 50%; */
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.containers .card:hover .contents {
  transform: translateY(0px);
  opacity: 1;
}

.containers .card .contents .price {
  position: absolute;
  top: -50px;
  right: 30px;
  font-size: 3em;
  color: black;
  pointer-events: none;
}

.containers .card .contents h3 {
  font-size: 1.3em;
  color: #000;
  z-index: 1;
}

.containers .card .contents p {
  font-size: 1em;
  color: #000;
  font-weight: 300;
}

.btn {
  color: #000;
}
.containers .card .contents .first {
  position: relative;
  top: -100px;
  left: 0;
  display: block;
}

.containers .card:hover .contents .first {
  display: none;
}

.containers .card .contents .second {
  display: none;
}

.containers .card:hover .contents .second {
  display: block;
}

#doner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Animations for button */

@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

.jumbotron h1 {
  color: #353535;
}
footer {
  margin-bottom: 0 !important;
  margin-top: 80px;
}
footer p {
  margin: 0;
  padding: 0;
}
span.icon {
  margin: 0 5px;
  color: #d64541;
}
h2 {
  color: #bdc3c7;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.mrng-60-top {
  margin-top: 60px;
}
/* Global Button Styles */
a.animated-button:link,
a.animated-button:visited {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  border-radius: 10px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
a.animated-button:link:after,
a.animated-button:visited:after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}
a.animated-button:link:hover,
a.animated-button:visited:hover {
  color: #fff;
  text-shadow: none;
}
a.animated-button:link:hover:after,
a.animated-button:visited:hover:after {
  height: 450%;
}
a.animated-button:link,
a.animated-button:visited {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

a.animated-button.thar-three {
  color: rgb(10, 10, 10);
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid black;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  border-radius: 10px;
}
a.animated-button.thar-three:hover {
  color: #f0bc1d !important;
  background-color: transparent;
  text-shadow: nthree;
}
a.animated-button.thar-three:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-three:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #f0bc1d !important;
  background: black;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 40px 0;
}

.container1 .card1 {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(0, 0, 0, 0.1), 5px 5px 15px rgba(0, 0, 0, 0.3),
    -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 25px 0px 25px 0px;

  transition: 0.5s;
}

.container1 .card1 .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #f0bc1d;
  border-radius: 25px 0px 25px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.container1 .card1 .box:hover {
  transform: translateY(-50px);
}

.container1 .card1 .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.container1 .card1 .box .content-card {
  padding: 20px;
  text-align: center;
}

.container1 .card1 .box .content-card h2 {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 8rem;
  color: rgba(47, 40, 40, 0.1);
}

.container1 .card1 .box .content-card h3 {
  font-size: 1.7rem;
  color: rgb(0, 0, 0);
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.container1 .card1 .box .content-card p {
  font-size: 1.3rem;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.container1 .card1 .box .content-card a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.container1 .card1 .box .content-card a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: rgb(246, 245, 245);
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: relative;
}
.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 50vw;
}
.timeline__event:nth-child(2n + 1) {
  flex-direction: row-reverse;
}
.timeline__event:nth-child(2n + 1) .timeline__event__date {
  border-radius: 0 6px 6px 0;
}
.timeline__event:nth-child(2n + 1) .timeline__event__content {
  border-radius: 6px 0 0 6px;
}
.timeline__event:nth-child(2n + 1) .timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event:nth-child(2n + 1) .timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  animation: fillLeft 2s forwards 4s ease-in-out;
}
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}
.timeline__event__content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 0 6px 6px 0;
}
.timeline__event__date {
  color: #f6a4ec;
  font-size: 1.5rem;
  font-weight: 600;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
}
.timeline__event__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9251ac;
  padding: 20px;
  align-self: center;
  margin: 0 20px;
  background: #f6a4ec;
  border-radius: 100%;
  width: 40px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 40px;
  position: relative;
}
.timeline__event__icon i {
  font-size: 32px;
}
.timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  animation: fillLeftOdd 2s forwards 4s ease-in-out;
}
.timeline__event__description {
  flex-basis: 60%;
}
.timeline__event--type2:after {
  background: #555ac0;
}
.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
  background: #555ac0;
}
.timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:before,
.timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__icon {
  background: #87bbfe;
  color: #555ac0;
}
.timeline__event--type2 .timeline__event__icon:before,
.timeline__event--type2 .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}
.timeline__event--type3:after {
  background: #24b47e;
}
.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
  background-color: #24b47e;
}
.timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:before,
.timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__icon {
  background: #aff1b6;
  color: #24b47e;
}
.timeline__event--type3 .timeline__event__icon:before,
.timeline__event--type3 .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}
.timeline__event:last-child .timeline__event__icon:before {
  content: none;
}
@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event__content {
    width: 100%;
  }
  .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
  .timeline__event__icon:before,
  .timeline__event__icon:after {
    display: none;
  }
  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n + 1) {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event:nth-child(2n + 1) .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n + 1) .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
}
@keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@keyframes fillTop {
  100% {
    top: 100%;
  }
}
@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.cards__item {
  height: 400px;
}
.card1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}
.card:nth-child(1) {
  background-image: linear-gradient(45deg, #f0bc1d, #f0bc1d);
}
.card:nth-child(2) {
  background-image: linear-gradient(45deg, #f0bc1d, #f0bc1d);
}
.card:nth-child(3) {
  background-image: linear-gradient(45deg, #f0bc1d, #f0bc1d);
}
.card__frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card__picture {
  margin-bottom: 12px;
  filter: invert(1);
}
.card__picture img {
  display: block;
  max-width: 100%;
  height: auto;
}
.card__title {
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.card__overlay {
  color: black;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  /* background-color: #fff; */
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0);
  transition: 0.5s;
  cursor: pointer;
}

.card__overlay::before {
  content: "Read";
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.card__overlay:hover,
.card__overlay:focus {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  opacity: 0.9;
}
.card__overlay:hover::before,
.card__overlay:focus::before {
  content: none;
}
.card:nth-child(1) .card__overlay {
  background-image: linear-gradient(45deg, #111, #111);
}
.card:nth-child(2) .card__overlay {
  background-image: linear-gradient(45deg, #111, #111);
}
.card:nth-child(3) .card__overlay {
  background-image: linear-gradient(45deg, #111, #111);
}
.card__content {
  color: black important;
  z-index: 1;
  padding: 20px;
  line-height: 1.4;

  color: black;
  visibility: hidden;
  box-sizing: border-box;
  pointer-events: none;
  transition: 0s;
}
.card__overlay:hover ~ .card__content {
  color: black;
  visibility: visible;
  transition: 0.2s 0.3s;
  z-index: 10000;
}
.card__content h2 {
  margin: 0;
  margin-bottom: 16px;
}
.card __content p {
  color: #000 important;
}
.what {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 76px;
}
.container5 {
  width: max-content;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}
.container5 {
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  margin: 0 -3rem 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.container5:before,
.container5:after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  border: 0.75rem solid transparent;
  border-bottom: 0.75rem solid #f2f0f0;
  transform-origin: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.container5:before {
  left: 0;
  transform: translateY(-0.45rem) rotate(135deg) translateX(-0.4rem);
}
.container5 :after {
  right: 0;
  transform: translateY(-0.45rem) rotate(-135deg) translateX(0.4rem);
}
.container5 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3rem;
  font-size: 80px;
  font-size: 19px;
  color: #2980b9;
  opacity: 1.7;
  transition: 0.25s;
  padding: 2%;
}
.container5 :hover {
  opacity: 1;
}
.container5 .list5 .num {
  /* position: absolute; */
  left: 1rem;
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: flex-start;
  transition: 0.25s;
  border: 1px solid #000;
  border-radius: 15px;
  margin-bottom: 5px;
  background-color: #f0bc1d;
}
/* .container5 .list5 .num:nth-child(0):before {
  content: "0";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  transition: 0.25s;
} */
.num {
  font-size: 19px;
}
.ico {
  font-size: 15px;
  letter-spacing: 20px;
}
.container5 .list5 .num:nth-child(1):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(2):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(3):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(4):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(5):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(6):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(7):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(8):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(9):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(10):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num:nth-child(11):before {
  content: "\203A";
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  width: 2rem;
  opacity: 1;
  transition: 0.25s;
}
.container5 .list5 .num h3 {
  position: relative;
  left: 1.2rem;
  color: black;
  font-size: 22px;
  transition: 0.25s;
  margin: 0;
}
.container5 .list5 .num:hover {
  background-color: #c2bdbd7c;
  cursor: pointer;
}
.container5 .list5 .num:hover:before {
  opacity: 0.7;
}
.container5 .list5 .num:hover h3 {
  color: white;
}
.message5 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-top: 2rem;
}
@media (max-width: 786px) {
  .cards {
    display: flex;
    flex-direction: column;
  }
}
.conp {
  padding: 3%;
  font-weight: 200;
}

@media (max-width: 425px) {
  .board {
    /* margin-top: 70px; */
  }
}
