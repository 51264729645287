.h2-title {
  color: #f0bc1d;
  font-size: 30px;
}
.cont-bg {
  background-image: url("../assets/contact.webp") !important;
  background-position: center;
  background-size: cover;
  margin-top: 18px;
  padding: 5%;
}
iframe {
  width: 100%;
  height: 60vh;
  box-shadow: 0 14px 18px 0 rgba(0, 0, 0, 0.2),
    0 16px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 3;
}
.addd {
  color: #f0bc1d;
  font-weight: bold;
  font-size: 19px;
}
.contact-content {
  color: whitesmoke;
  font-size: 22px;
}
iframe:hover {
  z-index: 5;
}

.cont {
  margin: 3%;
  align-items: center;
  margin-top: 40px;
}

#con-card {
  align-items: baseline;
  width: 100%;
  height: 100%;
  padding: 5%;
  border-radius: 20px 0 20px 0;
  background: url("../assets/illu1.png");
  background-size: contain;
  margin-right: 10%;
  margin-bottom: 8%;
  border: 1px solid rgba(175, 132, 132, 0.18);
}
/* 
#con-card:hover {
  background-color: rgba(109, 109, 109, 0.212);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
  z-index: 10;
} */
.directors,
.address {
  text-align: center;

  /* z-index: 1; */
}
.col-full-width {
  width: 100%;
}
.social-icons {
  position: relative;
  top: 20px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons li {
  list-style: none;
}

.social-icons li a {
  display: inline-block;
  margin-left: 15px;
  background-color: #222;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.2% ease-in-out;
}

.social-icons li a:hover {
  background-color: #f0bc1d;
  transform: translateY(-10px);
}

.social-icons li a img {
  filter: invert(1);
  transform: scale(0.5);
}

@media only screen and (max-width: 600px) {
  #con-card {
    padding: 5%;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .cont-bg {
    margin-top: 40px;
    background-size: cover;
  }
}
