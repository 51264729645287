/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800&display=swap"); */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */

.first-section {
  background-color: #28292e;
  margin-top: 90px;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outline {
  display: flex;
  align-items: center;
  border: 3px solid #f0bc1d;
  width: 100%;
  height: 80vh;
  margin: 3%;
}

.inner-content-left {
  font-size: larger;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #28292e;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 1% 3%;
  background-color: #f0bc1d;
  height: 80vh;
  width: 30vw;
}

.inner-content-right {
  width: 100%;
  height: 100%;
  background: url("https://images.unsplash.com/photo-1504754524776-8f4f37790ca0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80");
  background-position: center;
  background-size: cover;
}

.slider-image {
  height: 90vh !important;
  background-position: center;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 11px 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  z-index: 2;
  background: url("../assets/atti-back1.webp");
  background-size: contain;
}

header .logo {
  position: relative;
  height: 40px;
  width: auto;
}

header .navigation {
  position: relative;
  display: flex;
}

header .navigation li {
  list-style: none;
}

header .navigation li a {
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  display: inline-block;
  color: white;
  /* font-weight: 700; */
  font-size: 23px;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  margin-left: 15px;
  position: relative;
  padding-top: 5px;
}

/* header .navigation li a:hover {
  display: inline-block;
  background-color: transparent;
} */
header .navigation li a::before,
.nav-active::after,
header .navigation li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: whitesmoke;
  left: 0;
  transition: all 0.5s;
  transform: scaleX(0);
}

.nav-active::after {
  transform: scaleX(1) !important;
}

header .navigation li a::before {
  top: 0;
  transform-origin: left;
}

header .navigation li a::after {
  bottom: 0;
  transform-origin: right;
}

header .navigation li a:hover::before,
header .navigation li a:hover::after {
  transform: scaleX(1);
}

.button-first {
  background-color: #f0bc1d !important;
}
.button-first:hover {
  background: transparent !important;
  border: 1px solid #f0bc1d !important;
  color: aliceblue !important;
}

/* .textBox {
  position: relative;
  top: -300px;
  left: -64px;
} */

.carousel {
  position: relative;
  top: 87px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #f0bc1d;
  width: 3rem;
  height: 3rem;
}
.carousel-caption {
  position: absolute;
  top: 90px;

  z-index: 0 !important;
}
h2.heading-main {
  color: #ffbf00 !important;
}

.carousel-indicators {
  display: none;
}
.carousel-caption h2 {
  color: white;
}
.carousel-caption p {
  color: white;
  font-weight: lighter;
}
@media (max-width: 1014px) {
  header .navigation {
    display: none;
  }

  .slider-image {
    height: 50vh !important;
  }

  .carousel-caption {
    z-index: 1;
    padding: 0;
  }
  header .navigation.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f0bc1d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding-right: 60px;
  }
  header .navigation.active li {
    margin: 20px 0;
  }
  header .navigation.active li a {
    font-size: 2.5rem;
  }
  header .navigation.active li a:hover {
    color: rgb(255, 255, 255);
  }
  .toggle {
    position: relative;
    width: 30px;
    height: 30px;
    background: url("./first-sec/menu.png");
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000;
    transition: 0.3s;
  }

  .toggle.active {
    position: fixed;
    right: 29px;
    background: url("./close-removebg-preview.png") ;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
  }
  .content .imgBox img {
    width: 100%;
  }
  header .logo {
    z-index: 100;
  }
  .outline {
    flex-direction: column;
  }
  .inner-content-left {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .carousel {
    top: 70px;
  }

  .slider-image {
    height: 40vh !important;
  }
  .carousel-caption h2 {
    font-size: 13px;
  }
  .carousel-caption p {
    font-size: 11px;
  }
  .a1 {
    height: 26px;
    width: 40px;
  }

  .a2 {
    height: 30px;
    width: 70px;
  }
}
