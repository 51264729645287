@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");

$bg: #fff;
$text: #7288a2;
$gray: #4d5974;
$lightgray: #e5e5e5;

.faq-title {
  color: #f0bc1d;
}
.sep {
  color: #f0bc1d;
  background-color: black;
}
.accordion {
  .accordion-item {
    border: none;
    button[aria-expanded="true"] {
      border-bottom: 1px solid #fff;
      
    }
  }
  button {
    position: relative;
    display: flex;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: #7288a2;
    font-size: 1.5rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
      color: #f0bc1d;
      &::after {
        cursor: pointer;
        color: #f0bc1d;
        border: 1px solid #f0bc1d;
      }
    }
    .accordion-title {
      font-size: 22px;
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 35px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded="true"] {
    color: #f0bc1d;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 30em;
      font-size: 20px !important;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    font-size: 1.5rem;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
  }
  p {
    font-size: 20px;
    margin: 2em 0;
  }
  li {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 2em 0;
  }
}
