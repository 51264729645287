.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0;
  list-style-type: none;
}
.menu-title {
  margin-top: 15rem;
  text-align: center;
  color: black;
}
.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}
.card__image {
  width: 100%;
  height: auto;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: #f0bc1d;
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
  height: 250px;
}
.card:hover .card__overlay {
  transform: translateY(0);
}
.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}
.card__title {
  text-align: center;
  color: black;
}
.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}
.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}
.card:hover .card__header {
  transform: translateY(3);
}
.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
/* .card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: #6a515e;
} */
.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: black;
}
.card__status {
  font-size: 0.8em;
  color: black;
}
.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: black;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
