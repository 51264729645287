.work-container {
  margin-top: 67px;
}
.work-hero {
  position: relative;
  /* top: 96px; */
  width: 100%;
  height: max-content;
  background: url("../assets/illu1.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
}
.hero-text {
  /* color: #febc1d; */
  color: lightcyan;
  text-transform: uppercase;
  font-weight: 500;
}

.job-req {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5%;
  background: url("./Work.jpeg");
  color: rgb(79, 82, 79);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.job-req h3 {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.582);
  font-size: 40px;
  text-align: center;
  padding: 20px;
  margin: 10px;
}
.article-section {
  width: 700px;
}
.row {
  display: flex;
}
.req-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(10, 10, 10);
  color: whitesmoke;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  padding: 5%;
  height: 90%;
  width: 350px;
}

.req-card h4 {
  color: #f0bc1d;
  font-size: 25px;
}
.req-card p {
  font-size: 22px;
}

.read-more-btn {
  display: inline-block;
  background-color: #f0bc1d;
  padding: 2%;
  color: black;
  border: 1px solid #f0bc1d;
}
.read-more-btn:hover {
  background: transparent;
  /* border: 1px solid #f0bc1d; */
  color: whitesmoke;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  border-bottom: none;
  background-color: #262626;
  color: #fff;
}
/* #contact_sujet {
  color: black;
} */
.input_wrapper input {
  color: black;
  font-size: 24px;
}

@media (max-width: 376px) {
  .work-hero {
    top: 68px !important;
  }
  .req-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(10, 10, 10);
    color: whitesmoke;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    padding: 5%;
    /* height: 90%;
    width: 350px; */
  }
}
@media (max-width: 991px) {
  .req-cards {
    flex-direction: column;
  }
  .job-req {
    top: 42px;
  }
  .job-req h3 {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.582);

    font-size: 30px;
    text-align: center;
  }
  /* .work {
    top: 67px;
  } */
  .body1 {
    top: 42px !important;
  }
  .work-hero {
    top: 79px;
  }
  .req-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(10, 10, 10);
    color: whitesmoke;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
    padding: 5%;
    /* height: 90%; */
    width: 85vw;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6% auto;
  }
  .article-section {
    width: 100%;
  }
}

.body1 {
  padding: 2%;
  background: url("./wwu1.png");
  background-position: center;
  background-size: cover;
  position: relative;
  /* top: 87px; */
}

#contact {
  padding: 3%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.article-section {
  background-color: #f0bc1d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4%;
  box-shadow: 1px 1px 0px 11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 0px 11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 0px 11px rgba(0, 0, 0, 0.75);
  margin: 40px;
}

.sub-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 70px;
}
